export default [
	{
		image: 'zulutrade_06.jpg',
		tag: 'ZuluTrade',
		heading: 'Socialise with ZuluTrade – Official Night Party Sponsor of iFX EXPO International 2023',
		date: 'Wednesday, 30/08/2023',
		description: `Leading social copy trading platform, Finvasia Group’s <a href='https://www.zulutrade.com/' style="color:#FF7200" target='_blank'>ZuluTrade,</a>, will be the Official Night Party Sponsor of the largest B2B fintech expo of the year, iFX EXPO International 2023 ...`,
		link: 'https://cyprus-mail.com/2023/08/30/socialise-with-zulutrade-official-night-party-sponsor-of-ifx-expo-international-2023/'
	},
	{
		image: 'zulutrade_05.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Connects Traders with 1000+ Leading Brokers Worldwide',
		date: 'Wednesday, 23/08/2023',
		description: `In today’s rapidly evolving trading landscape, traders seek out platforms that provide exposure to global markets, innovative tools and tailored trading experiences. <a href='https://www.zulutrade.com/' style="color:#FF7200" target='_blank'>ZuluTrade,</a> , the social trading platform part of Finvasia Group ...`,
		link: 'https://latinamericanpost.com/45129-zulutrade-connects-traders-with-1000-leading-brokers-worldwide'
	},
	{
		image: 'zulutrade_04.jpg',
		tag: 'ZuluTrade',
		heading: 'Optimise Your Trading Strategy with ZuluTrade’s Powerful Trading Calculators',
		date: 'Wednesday, 16/08/2023',
		description: `In today’s dynamic and ever-evolving financial markets, successful trading rests on a comprehensive understanding of risk management and well-informed decision-making. <a href='https://www.zulutrade.com/trading-tools?utm_source=news&utm_medium=pr&utm_campaign=trading_tools' style="color:#FF7200" target='_blank'>ZuluTrade,</a> Finvasia’s innovative...`,
		link: 'https://www.fxempire.com/news/article/optimise-your-trading-strategy-with-zulutrades-powerful-trading-calculators-1368429'
	},
	{
		image: 'zulutrade_03.jpg',
		tag: 'ZuluTrade',
		heading: 'Discover the Power of Social Trading with ZuluTrade’s Innovative Portfolio Building',
		date: 'Monday, 07/08/2023',
		description: `In the dynamic realm of social trading, <a href='https://www.zulutrade.com/' style="color:#FF7200" target='_blank'>ZuluTrade</a> introduces an innovative approach to constructing a well-rounded investment portfolio powered by technology.`,
		link: 'https://financefeeds.com/creating-a-social-trading-portfolio-with-zulutrade-a-tech-driven-approach/'
	},
	{
		image: 'zulutrade_02.jpg',
		tag: 'ZuluTrade',
		heading: 'Empowering Brokers with a Platform-agnostic Social Trading Solution',
		date: 'Thursday, 27/07/2023',
		description: `The Finvasia Group company started out in 2007 with a mission to build and support the world’s largest trading community for knowledge sharing....`,
		link: 'https://www.investing.com/studios/article-382394'
	},
	{
		image: 'zulutrade_01.jpg',
		tag: 'ZuluTrade',
		heading: 'Join Social and Copy Trading Leader ZuluTrade as It Continues Its World Tour',
		date: 'Thursday, 20/07/2023',
		description: `<a href='https://www.zulutrade.com/' style="color:#FF7200" target='_blank'>ZuluTrade,</a> the social and copy trading veteran, started 2023 with an exciting product pipeline and an equally exciting agenda packed with international events....`,
		link: 'https://www.dailyforex.com/forex-figures/2023/07/join-leader-zulutrade-20-july-2023/198540'
	},
	{
		image: 'zulunewpr8.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade’s Subscription Plan Page Now Live',
		date: 'Monday, 10/07/2023',
		description: `Finvasia’s well-known social and copy trading leader ZuluTrade recently introduced a new <a href='https://www.zulutrade.com/pricing?utm_source=news&utm_medium=pr&utm_campaign=subscriptionplan' style="color:#FF7200" target='_blank'>Subscription Plan</a> for copy traders. Free of transaction-based platform fees, it offers a transparent ...`,
		link: 'https://www.investing.com/studios/article-382386'
	},
	{
		image: 'zulunewpr7.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Strikes Partnership with Hextra Prime',
		date: 'Monday, 03/07/2023',
		description: `As it continues to enjoy the spotlight, Finvasia’s leading social trading platform <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=zt_hextraprime' style="color:#FF7200" target='_blank'>ZuluTrade</a> has made waves in the media with its recent partnership announcement.The company is excited to announce its ...`,
		link: 'https://www.investing.com/studios/article-382383'
	},
	{
		image: 'zulunewpr6.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Crowned ‘Best Social Trading Solution - APAC’',
		date: 'Monday, 26/06/2023',
		description: `A name that has held the front page for years with its innovative social trading platform, <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=pr&utm_campaign=ifx_apac_asia' style="color:#FF7200" target='_blank'>ZuluTrade,</a> is on an award-winning streak. The ‘Best Social Trading Solution...`,
		link: 'https://www.malaysiakini.com/announcement/669992'
	},
	{
		image: 'zulunewpr5.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Participates in iFX EXPO Asia 2023 as Diamond Sponsor',
		date: 'Monday, 19/06/2023',
		description: `<a href='https://www.zulutrade.com/' style="color:#FF7200" target='_blank'>ZuluTrade,</a> a Finvasia Group company and provider of the world-leading social trading and wealth management platform, has announced its participation in iFX EXPO Asia as a Diamond...`,
		link: 'https://www.malaysiakini.com/announcement/669277'
	},
	{
		image: 'zulunewpr4.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade to Showcase Updated Affiliate Program at SiGMA Americas',
		date: 'Monday, 12/06/2023',
		description: `ZuluTrade, a world-leading social trading and wealth management platform provider part of Finvasia Group, has announced its participation in SiGMA Americas, Brazil. The company will...`,
		link: 'https://www.fxempire.com/news/article/zulutrade-to-showcase-updated-affiliate-program-at-sigma-americas-1353658'
	},
	{
		image: 'zulunewpr3.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Facilitates Strategy Developers in Opening New Wealth Avenues',
		date: 'Monday, 07/06/2023',
		description: `Strategy Developers can enjoy a smoother user experience with <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=StrategyD' style="color:#FF7200" target='_blank'>ZuluTrade’s</a> reinforced social trading infrastructure. Thanks to its versatile interface design, the platform allows strategy...`,
		link: 'https://financialit.net/news/trading-systems/zulutrade-facilitates-strategy-developers-opening-new-wealth-avenues'
	},
	{
		image: 'zulunewpr2.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade’s Social Feed: Enhancing Users’ experience as Community Contributors',
		date: 'Monday, 22/05/2023',
		description: `Despite its touted simplicity, social trading can pose a real challenge when it comes to following experienced and knowledgeable traders , especially for those who are just starting out. ...`,
		link: 'https://www.fxempire.com/news/article/zulutrades-social-feed-enhancing-users-experience-as-community-contributors-1348492'
	},
	{
		image: 'zulunewpr1.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Receives ‘Best Wealth Management Platform Africa’ Award',
		date: 'Tuesday, 16/05/2023',
		description: `<a href='https://www.zulutrade.com/?utm_source=news&utm_medium=PR&utm_campaign=FMAS23awards' style="color:#FF7200" target='_blank'>ZuluTrade</a>, has been awarded the ‘Best Wealth Management Platform Africa’ by the Financial Achievements in Markets Excellency Awards...`,
		link: 'https://www.forexlive.com/Education/zulutrade-receives-best-wealth-management-platform-africa-award-20230516/'
	},
	{
		image: 'zuluprnewimg2.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Sponsors the Finance Magnates Africa Summit',
		date: 'Tuesday, 02/05/2023',
		description: `<a href='https://www.zulutrade.com/?utm_source=news&utm_medium=article&utm_campaign=FMAS23' style="color:#FF7200" target='_blank'>ZuluTrade</a>, Finvasia Group’s pioneering social wealth management service provider, announced its participation and sponsorship of the Finance Magnates Africa Summit (FMAS:23)..`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-sponsors-the-finance-magnates-africa-summit/'
	},
	// {
	// 	image : 'zuluprnewsimg6.jpeg',
	// 	tag : 'Zulutrade',
	// 	heading : 'ZuluTrade Sponsors the Finance Magnates Africa Summit',
	// 	date : 'Wednesday, 03/05/2023',
	// 	description : `<a href='https://www.zulutrade.com/?utm_source=news&utm_medium=article&utm_campaign=FMAS23' target='_blank' style='color:#FF7200'>ZULUTrade</a>, Finvasia Group’s pioneering social wealth management service provider, announced its participation and sponsorship of the Finance Magnates Africa Summit (FMAS:23) in Johannesburg,..`,
	// 	link : 'https://www.financemagnates.com/thought-leadership/zulutrade-sponsors-the-finance-magnates-africa-summit/'
	// },
	{
		image: 'zuluprnewsimg4.jpeg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Revs Up Its Platform for Enhanced User Experience',
		date: 'Thursday, 27/04/2023',
		description: `Finvasia Group’s social trading and investing firm <a href='https://www.zulutrade.com/?utm_source=news&utm_medium=mktnews&utm_campaign=2.0Launch' target='_blank' style='color:#FF7200'>ZULUTrade</a> has recently launched its enhanced Social Intelligence Platform. The innovation comes as an overhaul of its..`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-revs-up-its-platform-for-enhanced-user-experience/'
	},
	// {
	// 	image : 'zulunewprimg4.jpg',
	// 	tag : 'Zulutrade',
	// 	heading : 'ZULUTRADE REVAMPS PLATFORM FOR BETTER USER EXPERIENCE',
	// 	date : 'Monday, 01/05/2023',
	// 	description : `<a href='https://www.zulutrade.com/?utm_source=news&utm_medium=mktnews&utm_campaign=2.0Launch' target='_blank' style='color:#FF7200'>ZULUTrade</a>, the social trading and wealth management platform owned by Finvasia Group, has released its enhanced Social Intelligence Platform. This..`,
	// 	link : 'https://financefeeds.com/zulutrade-revamps-platform-for-better-user-experience/'
	// },
	{
		image: 'zulunewprimg3.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluGuard: A Better Way to Manage Risk in Social Trading',
		date: 'Thursday, 06/04/2023',
		description: 'ZuluTrade, the novel social trading and social wealth management platform owned by Finvasia Group, reveals its “secret formula” for risk managementin social trading...',
		link: 'https://www.financemagnates.com/thought-leadership/zuluguard-a-better-way-to-manage-risk-in-social-trading/'
	},
	{
		image: 'zulunewprimg2.jpg',
		tag: 'ZuluTrade',
		heading: 'EXCLUSIVE: ZuluTrade’s Revamped Affiliate Program Is Now Live',
		date: 'Wednesday, 29/03/2023',
		description: 'ZuluTrade’s Affiliate Program offers new opportunities for website and blog owners, influencers, trading academies and professional traders..',
		link: 'https://www.forexlive.com/Education/exclusive-zulutrades-revamped-affiliate-program-is-now-live-20230329/'
	},
	{
		image: 'zulunewprimg1.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Launches Operations in Mauritius',
		date: 'Thursday, 23/03/2023',
		description: `Social trading leader <a href='https://www.zulutrade.com/' target='_blank' style='color:#FF7200'>ZULUTrade</a> expands its offering in the market with the addition of wealth..`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-launches-operations-in-mauritius/'
	},
	{
		image: 'ZuluTrade_id_5b1477.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Pushes the Boundaries of Social Trading',
		date: 'Wednesday, 15/03/2023',
		description: 'Considering its accessibility and the smooth investing experience it offers, the social trading sector has witnessed an exponential rise in the past decade and a half....',
		link: 'https://www.forexlive.com/Education/zulutrade-pushes-the-boundaries-of-social-trading-20230315/'
	},
	{
		image: 'ZuluTrade_id.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Teams Up with X-Open Hub Brokerage FXCentrum',
		date: 'Monday, 09/03/2023',
		description: 'ZuluTrade, the award-winning Finvasia Group social wealth management and social trading platform, has announced a new partnership with FxCentrum, a Seychelles based innovative brokerage firm.',
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-teams-up-with-x-open-hub-brokerage-fxcentrum/'
	},
	// {
	// 	image : 'news_7.png',
	// 	tag : 'Zulutrade',
	// 	heading : 'ZuluTrade Expands in Japan',
	// 	date : 'Tuesday, 14/02/2023',
	// 	description : 'ZuluTrade, the social trading platform that not so long ago rocked the stage in Dubai, winning the “Best Social Trading Solution - MEA” at the UF AWARDS MEA 2023, enjoys the spotlight of..',
	// 	link : 'https://www.financemagnates.com/thought-leadership/exclusive-zulutrade-expands-in-japan/'
	// },
	{
		image: 'news_11.png',
		tag: 'ZuluTrade',
		heading: 'EXCLUSIVE: ZULUTRADE INTEGRATES METATRADER5 AND ACTTRADER',
		date: 'Thursday, 16/02/2023',
		description: `A veteran in the social trading space, <a href='https://www.zulutrade.com/' target='_blank' style="color:#FF7200">ZuluTrade</a> recently attracted the industry’s attention with its groundbreaking announcement to incorporate two new platforms – MT5 and ActTrader. ...`,
		link: 'https://financefeeds.com/pr/exclusive-zulutrade-integrates-metatrader5-and-acttrader/'
	},
	{
		image: 'news_7.png',
		tag: 'ZuluTrade',
		heading: 'Exclusive: ZuluTrade Expands in Japan',
		date: 'Tuesday, 14/02/2023',
		description: `<a href='https://www.zulutrade.com/' target='_blank' style="color:#FF7200">ZuluTrade</a>, the social trading platform that not so long ago rocked the stage in Dubai, winning the “Best Social Trading Solution - MEA” at the UF AWARDS MEA 2023,...`,
		link: 'https://www.financemagnates.com/thought-leadership/exclusive-zulutrade-expands-in-japan/'
	},
	{
		image: 'news_8.png',
		tag: 'ZuluTrade',
		heading: 'ZULUTRADE ADDS ONE MORE AWARD TO ITS COLLECTION: “BEST SOCIAL TRADING SOLUTION – MEA” 2023',
		date: 'February 6, 2023',
		description: `<a href='https://www.zulutrade.com/' traget='_blank' style="color:#FF7200">ZuluTrade</a>, a Finvasia Group company, is an innovator in the social trading and wealth management industry and has amazed industry leaders yet again at the UF AWARDS MEA 2023..`,
		link: 'https://financefeeds.com/pr/zulutrade-adds-one-more-award-to-its-collection-best-social-trading-solution-mea-2023/'
	},
	{
		image: 'zuluprnewimg1.jpg',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Strengthens Reach in the Middle East',
		date: 'January 9, 2023',
		description: `The leading social and copy trading platform, <a href='https://www.zulutrade.com/' target='_blank' style="color:#FF7200">ZuluTrade</a>, has successfully broadened its reach in the Middle East, with an increase in the average..`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-strengthens-reach-in-the-middle-east/'
	},
	{
		image: 'news_13.jpg',
		tag: 'ZuluTrade',
		heading: 'ZULUTRADE ANNOUNCES IT WILL DROP PROFIT-SHARING FEES FROM INVESTORS’ ACCOUNTS',
		date: 'Thursday, 24/11/2022',
		description: `Undertaking a major change in its leaders’ compensation model, <a href='https://www.zulutrade.com/' target='_blank' style="color:#FF7200">ZuluTrade</a> has announced that it will drop the profit-sharing fees from the followers’ accounts..`,
		link: 'https://financefeeds.com/pr/zulutrade-announces-it-will-drop-profit-sharing-fees-from-investors-accounts/'
	},
	{
		image: 'news_2.png',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Launches Integrated Broker Partnerships',
		date: 'Monday, 14/11/2022',
		description: `<a href='' target='_blank'>ZuluTrade</a> , the oldest social wealth management platform, has launched Integrated Broker Partnerships. The added feature is the first of its kind...`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-launches-integrated-broker-partnerships/'
	},
	{
		image: 'news_6.png',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Growth Plan does not include Unlicensed Brokers',
		date: 'Friday, 04/11/2022',
		description: `ZuluTrade has announced that it will continue its growth in the B2B space with licensed brokers only, terminating agreements that fail to pass its compliance curation process as a part of its new transition to ZuluTrade 2.0.`,
		link: 'https://financefeeds.com/pr/zulutrade-growth-plan-does-not-include-unlicensed-brokers/'
	},
	{
		image: 'zuluprsimg1.webp',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade wins ‘Best Social Wealth Management Platform’ award at the Forex Expo Dubai',
		date: 'Wednesday, 02/11/2022',
		description: `Athens, Greece: ZuluTrade, a Finvasia Group company, has won the ‘Best Social Wealth Management Platform’ award at the Forex Expo Dubai...`,
		link: 'https://www.zawya.com/en/press-release/companies-news/zulutrade-wins-best-social-wealth-management-platform-award-at-the-forex-expo-dubai-flfeodam'
	},
	// {
	// 	image: 'news_9.png',
	// 	tag: 'ZuluTrade',
	// 	heading: 'ZuluTrade Promotes Efstathios Xenos as Vice President of Growth',
	// 	date: 'Wednesday, 21/09/2022',
	// 	description: `ZuluTrade, a popular copy <a href='https://www.financemagnates.com/terms/t/trading-platform/' target='_blank' style="color:#FF7200">trading platform</a> owned by the Finvasia Group, has promoted Efstathios Xenos as the Vice President of Growth. He was previously the company’s..`,
	// 	link: 'https://www.financemagnates.com/executives/moves/zulutrade-promotes-efstathios-xenos-as-vice-president-of-growth/'
	// },
	// {
	// 	image: 'news_5.png',
	// 	tag: 'ZuluTrade',
	// 	heading: 'ZuluTrade Appoints 3 Executives to Drive its Global Growth Plan',
	// 	date: 'Wednesday, 14/09/2022',
	// 	description: `ZuluTrade, a Finvasia Group company, has promoted a selected trio into its ranks as part of a commitment to scale its global operations and human capital growth..`,
	// 	link: 'https://amp.fxempire.com/en/zulutrade-appoints-3-executives-to-drive-its-global-growth-plan/1126499'
	// },
	{
		image: 'news_10.png',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Named as the Official Global Partner at iFX EXPO ASIA 2022 in Bangkok',
		date: 'Monday, 12/09/2022',
		description: `ZuluTrade, part of the Finvasia Group, will be at iFX EXPO Asia 2022 as the Official Global Partner for the event. The company aspires to become the world’s largest social trading community,..`,
		link: 'https://www.financemagnates.com/thought-leadership/zulutrade-named-as-the-official-global-partner-at-ifx-expo-asia-2022-in-bangkok/'
	},
	{
		image: 'news_9.png',
		tag: 'ZuluTrade',
		heading: 'Finvasia Group Appoints Tajinder Virk as Interim CEO of ZuluTrade',
		date: 'Monday, 18/04/2022',
		description: `ATHENS, Greece--(BUSINESS WIRE)--Finvasia today announced that it has appointed Tajinder Virk as the interim Chief Executive Officer for ZuluTrade. He will replace the previous CEO from the company, effective immediately...`,
		link: 'https://www.businesswire.com/news/home/20220418005251/en/Finvasia-Group-Appoints-Tajinder-Virk-as-Interim-CEO-of-ZuluTrade'
	},
	{
		image: 'news_9.png',
		tag: 'ZuluTrade',
		heading: 'ZuluTrade Eyes Expansion in 2022, Seeks More Regulatory Licenses',
		date: 'Friday, 21/01/2022',
		description: `ZuluTrade, which was acquired by Finvasia last month, revealed on Friday that it is planning for expansion this year and adding new capabilities to its existing social trading platform...`,
		link: 'https://www.financemagnates.com/forex/products/zulutrade-eyes-expansion-in-2022-seeks-more-regulatory-licenses/'
	}
]